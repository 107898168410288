import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import PageHeader from "../components/english/PageHeader"
import ContactUsContent from "../components/english/contact-us/ContactUsContent"

const ContactUs = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout seoTitle="Contacts" seoKeywords="Contact JFEEI">
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="Contacts"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <ContactUsContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default ContactUs

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "contacts" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
